import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TokenStorageService } from './token-storage.service';
import { environment } from '../../environments/environment';
import { Users } from '../_models/users';
import { Discount } from '../_models/discount';
import { FarmingType } from '../_models/farmingType';
import { BusinessSector } from '../_models/businessSector';

const API = `${environment.api}/`;

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  constructor(private http: HttpClient, private tokenStorage: TokenStorageService) { }

  getMe(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${this.tokenStorage.getUser().token}`, 'Access-Control-Allow-Origin':'*', 'Accept': '*/*' })
    };
    return this.http.get<Users>(API + "me", httpOptions);
  }

  getUsers(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${this.tokenStorage.getUser().token}`, 'Access-Control-Allow-Origin':'*', 'Accept': '*/*' })
    };
    return this.http.get<Users>(API + "users?pagination=false", httpOptions);
  }

  addUsers(userData: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${this.tokenStorage.getUser().token}`, 'Access-Control-Allow-Origin':'*', 'Accept': '*/*' })
    };
    var data = {};
    data = {
      'email': userData.email,
      'customerCode': userData.customerCode,
      'companyName': userData.companyName,
      'siret': userData.siret,
      'enabled': userData.enabled,
      'societyNumber': userData.societyNumber,
      'type': userData.type,
      'proPar': userData.proPar,
      'origin': userData.origin,
      'naf': userData.naf,
      'intracommunityVat': userData.intracommunityVat,
      'gender': userData.gender,
      'phoneNumber': userData.phoneNumber,
      'fax': userData.fax,
      'cooperative': userData.cooperative,
      'languageCode': userData.languageCode,
      'active': userData.active,
      'paymentReminder': userData.paymentReminder,
      'traiteReminder': userData.traiteReminder,
      'buildingTech': userData.buildingTech,
      'farmingTech': userData.farmingTech,
      'veterinarian': userData.veterinarian,
      'numberSows': userData.numberSows,
      'webRegistered': userData.webRegistered,
      'display': userData.display,
      'suspend': userData.suspend,
      'farmingType': userData.farmingType,
      'businessSector': userData.businessSector,
      'discount': userData.discount,
      'password': userData.password,
      'name': userData.name,
      'lastName': userData.lastName,

      'pricingPolicy': ["string"],
      'bankingInformations': ["string"]
    }

    const body = data
    return this.http.post<Users>(API + "users", body, httpOptions);
  }

  updateUsers(id: number, userData: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/merge-patch+json', 'Authorization': `Bearer ${this.tokenStorage.getUser().token}`, 'Access-Control-Allow-Origin':'*', 'Accept': '*/*' })
    };
    var data = {};
    data = {
      'email': userData.email,
      'customerCode': userData.customerCode,
      'companyName': userData.companyName,
      'siret': userData.siret,
      'enabled': userData.enabled,
      'societyNumber': userData.societyNumber,
      'type': userData.type,
      'proPar': userData.proPar,
      'origin': userData.origin,
      'naf': userData.naf,
      'intracommunityVat': userData.intracommunityVat,
      'gender': userData.gender,
      'phoneNumber': userData.phoneNumber,
      'fax': userData.fax,
      'cooperative': userData.cooperative,
      'languageCode': userData.languageCode,
      'active': userData.active,
      'paymentReminder': userData.paymentReminder,
      'traiteReminder': userData.traiteReminder,
      'buildingTech': userData.buildingTech,
      'farmingTech': userData.farmingTech,
      'veterinarian': userData.veterinarian,
      'numberSows': userData.numberSows,
      'webRegistered': userData.webRegistered,
      'display': userData.display,
      'suspend': userData.suspend,
      'farmingType': userData.farmingType,
      'businessSector': userData.businessSector,
      'discount': userData.discount,
      'password': userData.password,
      'name': userData.name,
      'lastName': userData.lastName,

      'pricingPolicy': ["string"],
      'bankingInformations': ["string"]
    }

    const body = data
    return this.http.patch<Users>(API + "users/" + id, body, httpOptions);
  }

  getOne(id: number): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${this.tokenStorage.getUser().token}`, 'Access-Control-Allow-Origin':'*', 'Accept': '*/*' })
    };
    return this.http.get<Users>(API + "users/" + id, httpOptions);
  }

  deleteUser(id: number): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${this.tokenStorage.getUser().token}`, 'Access-Control-Allow-Origin':'*', 'Accept': '*/*' })
    };
    return this.http.delete<Users>(API + "users/" + id, httpOptions);
  }

  getDiscounts(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${this.tokenStorage.getUser().token}`, 'Access-Control-Allow-Origin':'*', 'Accept': '*/*' })
    };
    return this.http.get<Discount>(API + "discounts?pagination=false", httpOptions);
  }

  getFarmingType(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${this.tokenStorage.getUser().token}`, 'Access-Control-Allow-Origin':'*', 'Accept': '*/*' })
    };
    return this.http.get<FarmingType>(API + "farming_types?pagination=false", httpOptions);
  }

  getBusinessSector(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${this.tokenStorage.getUser().token}`, 'Access-Control-Allow-Origin':'*', 'Accept': '*/*' })
    };
    return this.http.get<BusinessSector>(API + "business_sectors?pagination=false", httpOptions);
  }

  getCooperative(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${this.tokenStorage.getUser().token}`, 'Access-Control-Allow-Origin':'*', 'Accept': '*/*' })
    };
    return this.http.get<BusinessSector>(API + "cooperatives?pagination=false", httpOptions);
  }

  downloadTxt(urlFile: string) {
    return this.http.get(urlFile, {
      responseType: 'arraybuffer',
      headers: new HttpHeaders().append('Content-Type', 'application/txt'),
    });
  }
}
