import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';

import { LayoutService } from '../../../@core/utils';
import { filter, map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TokenStorageService } from '../../../_services/token-storage.service';
import { UsersService } from '../../../_services/users.service';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {
  [x: string]: any;

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;
  fullName = "";

  currentTheme = 'corporate';

  userMenu = [ { title: 'Déconnexion' } ];

  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private themeService: NbThemeService,
              private usersService: UsersService,
              private layoutService: LayoutService,
              private breakpointService: NbMediaBreakpointsService,
              private tokenStorageService: TokenStorageService) {
              menuService.onItemClick()
                .pipe(filter(({ tag }) => tag === this.tag))
                .subscribe(bag =>
                  {
                    if (bag.item.title == "Déconnexion"){
                      this.logout();
                    }
                  }
                );

  }

  ngOnInit() {
    this.currentTheme = this.themeService.currentTheme;

    this.usersService.getMe().subscribe(
      data => {
        this.fullName = data.name + " " + data.lastName;
      }
    )

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  logout(): void {
    this.tokenStorageService.signOut();
    window.location.reload();
  }
}
